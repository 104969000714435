import { useState } from "react";
import LoginPopup from "./LoginPopup";
import SignupPopup from "./SignupPopup";
import ForgotPasswordPopup from "./ForgotPasswordPopup";
// import WorldIdPopup from "./WorldIdPopup";
import { useAuth } from "../context/AuthContext";
import { Navigate, useSearchParams } from "react-router-dom";
import { APP_NAME } from "@/utils/constants";

export default function Hero() {
  const [searchParams] = useSearchParams();
  const [openPopup, setOpenPopup] = useState(
    searchParams.get("ref") ? "signup" : null,
  );

  const handleOpenSignin = () => {
    setOpenPopup("signin");
  };

  const handleOpenSignup = () => {
    setOpenPopup("signup");
  };

  const handleOpenResetPassword = () => {
    setOpenPopup("reset-password");
  };

  const handleClosePopup = () => {
    setOpenPopup(null);
  };

  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <Navigate to={location.state?.from || "/dashboard"} />;
  }

  return (
    <div className="">
      <header>
        <div className="w-full md:px-16 px-6 py-4">
          <div className="flex items-center gap-1">
            <img src="logo.svg" alt="" className="" />
            <h1 className="text-4xl font-semibold">{APP_NAME}</h1>
          </div>
        </div>
      </header>
      <div className="relative">
        <div className="flex md:flex-row flex-col cstm-div">
          {/* <div className="flex md:flex-row flex-col max-w-[95rem] m-auto"> */}
          <div className="md:w-[60%] md:py-20 py-12 md:px-6 px-10 md:max-w-xl m-auto">
            <div className="flex flex-col items-start gap-6">
              <img src="logo2.svg" alt="" className="" width="90" />
              <h1 className="md:text-6xl text-4xl md:leading-[4rem] md:pr-20">
                Providing Capital to Talented Founders
              </h1>
              <p className="md:text-base text-sm">
                Talk to an algorithm and receive money instantly.
              </p>
              <div className="flex gap-2 items-center">
                <button
                  className="bg-gradient-to-br from-[#77AAFF] to-[#2776F9] rounded-[5px] px-6 py-2"
                  onClick={handleOpenSignin}
                >
                  Login
                </button>
                <button
                  className="bg-transparent border border-white rounded-[5px] md:px-6 px-4 py-2"
                  onClick={handleOpenSignup}
                >
                  Create an Account
                </button>
              </div>
              <div className="flex gap-2 items-start mt-2">
                <a
                  href="https://discord.gg/Uesw5Aedef"
                  className="hover:scale-105 transition-all"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src="discord.svg" alt="" className="" width="40" />
                </a>
                <a
                  href="https://x.com/NKXCapital"
                  className="hover:scale-105 transition-all"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src="x.svg" alt="" className="" width="40" />
                </a>
                <a
                  href="https://t.me/nkxchat"
                  className="hover:scale-105 transition-all"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src="t.svg" alt="" className="" width="40" />
                </a>
              </div>
            </div>
          </div>
          <div className="md:w-1/2 flex justify-end bg-cstm bg-no-repeat bg-cover md:h-auto h-[500px] border-l border-[#04C8EC] cstm-brdr">
            {/* <img src="/Mask group.png" alt="" className="md:absolute right-0 top-0 lg:h-[800px] md:h-[700px] lg:w-[600px] md:w-[350px]" /> */}
          </div>
        </div>
      </div>
      {openPopup === "signin" && (
        <LoginPopup
          onCloseSignin={handleClosePopup}
          onOpenSignup={handleOpenSignup}
          onOpenResetPassword={handleOpenResetPassword}
        />
      )}
      {openPopup === "signup" && (
        <SignupPopup onCloseSignup={handleClosePopup} />
      )}
      {openPopup === "reset-password" && (
        <ForgotPasswordPopup
          onCloseResetPassword={handleClosePopup}
          onOpenSignin={handleOpenSignin}
        />
      )}
      {/* <WorldIdPopup /> */}
    </div>
  );
}
