import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import assessmentReducer from "./slices/assessment";
import sectionsReducer from "./slices/sections";
import chatReducer from "./slices/chat";
import notificationsReducer from "./slices/notifications";
import rewardsReducer from "./slices/rewards";

const rootReducer = combineReducers({
  auth: authReducer,
  assessment: assessmentReducer,
  sections: sectionsReducer,
  chat: chatReducer,
  notifications: notificationsReducer,
  rewards: rewardsReducer,
});

export default rootReducer;
