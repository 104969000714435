import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuth } from "../context/AuthContext";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const signupSchema = z
  .object({
    firstName: z.string().min(1, "First name is required"),
    lastName: z.string().min(1, "Last name is required"),
    email: z
      .string()
      .min(1, "Email is required")
      .email("Invalid email address"),
    password: z
      .string()
      .min(8, "Password must be at least 8 characters long")
      .max(100, "Password cannot exceed 100 characters")
      .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
      .regex(/[a-z]/, "Password must contain at least one lowercase letter")
      .regex(/[0-9]/, "Password must contain at least one number"),
    // .regex(/[@$!%*?&]/, "Password must contain at least one special character (@$!%*?&)"),
    confirmPassword: z.string().min(1, "Confirm Password is required"),
    referralCode: z.string().optional(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

export default function SignupPopUp({ onCloseSignup }) {
  const [signupError, setSignupError] = useState("");
  const { signUp, isLoading } = useAuth();
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get("ref");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: zodResolver(signupSchema),
    defaultValues: {
      ...(referralCode && { referralCode }),
    },
  });

  const onSubmit = async (data) => {
    try {
      await signUp(data);
      setSignupError("");
    } catch (error) {
      setSignupError(error.message);
    }
  };

  return (
    <div className="h-screen fixed inset-0 backdrop-blur-lg	flex justify-center items-center">
      <div className="sm:w-[480px] w-[300px]">
        <div className="flex justify-between bg-[#2776F9] sm:py-4 py-2 sm:px-8 px-6">
          <h1 className="sm:text-2xl text-xl font-semibold">
            Create an Account
          </h1>
          <img
            src="/close.svg"
            alt=""
            width="15"
            className="cursor-pointer"
            onClick={onCloseSignup}
          />
        </div>
        <div className="bg-[#040A20]">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="sm:py-12 py-6 sm:px-8 px-6 flex flex-col gap-5">
              <p className="text-red-500">{signupError}</p>
              <div className="flex flex-col gap-1">
                <div className="bg-[#1E2748] sm:py-3 py-2 sm:px-4 px-2 flex items-center gap-2 rounded-[5px]">
                  <input
                    type="text"
                    placeholder="First Name"
                    className="w-full bg-transparent focus-visible:outline-none placeholder:text-[#7787A6] sm:text-base text-sm"
                    {...register("firstName")}
                  />
                </div>
                <p className="text-red-500 text-sm">
                  {errors.firstName?.message}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <div className="bg-[#1E2748] sm:py-3 py-2 sm:px-4 px-2 flex items-center gap-2 rounded-[5px]">
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="w-full bg-transparent focus-visible:outline-none placeholder:text-[#7787A6] sm:text-base text-sm"
                    {...register("lastName")}
                  />
                </div>
                <p className="text-red-500 text-sm">
                  {errors.lastName?.message}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <div className="bg-[#1E2748] sm:py-3 py-2 sm:px-4 px-2 flex items-center gap-2 rounded-[5px]">
                  <input
                    type="text"
                    placeholder="Email Address"
                    className="w-full bg-transparent focus-visible:outline-none placeholder:text-[#7787A6] sm:text-base text-sm"
                    {...register("email")}
                  />
                </div>
                <p className="text-red-500 text-sm">{errors.email?.message}</p>
              </div>
              <div className="flex flex-col gap-1">
                <div className="bg-[#1E2748] sm:py-3 py-2 sm:px-4 px-2 flex items-center gap-2 rounded-[5px]">
                  <input
                    type="password"
                    placeholder="Password"
                    className="w-full bg-transparent focus-visible:outline-none placeholder:text-[#7787A6] sm:text-base text-sm"
                    {...register("password")}
                  />
                </div>
                <p className="text-red-500 text-sm">
                  {errors.password?.message}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <div className="bg-[#1E2748] sm:py-3 py-2 sm:px-4 px-2 flex items-center gap-2 rounded-[5px]">
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    className="w-full bg-transparent focus-visible:outline-none placeholder:text-[#7787A6] sm:text-base text-sm"
                    {...register("confirmPassword")}
                  />
                </div>
                <p className="text-red-500 text-sm">
                  {errors.confirmPassword?.message}
                </p>
              </div>
              <button
                className="cstm-btn rounded-[5px] px-6 py-3 md:mt-4 sm:text-base text-sm"
                disabled={isLoading}
              >
                {isLoading ? "Signing Up" : "Sign Up"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
