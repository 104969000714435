// import SignupPopUp from "./SignupPopup";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuth } from "../context/AuthContext";
import { useState } from "react";
import PropTypes from "prop-types";

const loginSchema = z.object({
  email: z.string().min(1, "Email is required"),
  password: z.string().min(1, "Password is required"),
});

export default function LoginPopup({
  onCloseSignin,
  onOpenSignup,
  onOpenResetPassword,
}) {
  const [loginError, setLoginError] = useState("");
  const { signIn, isLoading } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: zodResolver(loginSchema),
  });

  const onSubmit = async (data) => {
    try {
      await signIn(data);
      setLoginError("");
    } catch (error) {
      setLoginError(error.message);
    }
  };

  return (
    <div className="h-screen fixed inset-0 backdrop-blur-lg	flex justify-center items-center">
      <div className="sm:w-[480px]">
        <div className="flex justify-between bg-[#2776F9] sm:py-4 py-2 sm:px-8 px-6">
          <h1 className="sm:text-2xl text-xl font-semibold">Login</h1>
          <img
            src="/close.svg"
            alt=""
            width="15"
            className="cursor-pointer"
            onClick={onCloseSignin}
          />
        </div>
        <div className="bg-[#040A20]">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="sm:pt-12 pt-6 sm:pb-8 pb-6 sm:px-8 px-6 flex flex-col gap-5">
              <p className="text-red-500">{loginError}</p>
              <div className="flex flex-col gap-1">
                <div className="bg-[#1E2748] sm:py-3 py-2 sm:px-4 px-2 flex items-center gap-2 rounded-[5px]">
                  <img
                    src="/user.svg"
                    alt=""
                    width="20"
                    className="sm:w-[20px] w-[15px]"
                  />
                  <input
                    type="text"
                    placeholder="Email"
                    className="w-full bg-transparent focus-visible:outline-none placeholder:text-[#7787A6] sm:text-base text-sm"
                    {...register("email")}
                  />
                </div>
                <p className="text-red-500 text-sm">{errors.email?.message}</p>
              </div>
              <div className="flex flex-col gap-1">
                <div className="bg-[#1E2748] sm:py-3 py-2 sm:px-4 px-2 flex items-center gap-2 rounded-[5px]">
                  <img
                    src="/pass.svg"
                    alt=""
                    width="20"
                    className="sm:w-[20px] w-[15px]"
                  />
                  <input
                    type="password"
                    placeholder="Password"
                    className="w-full bg-transparent focus-visible:outline-none placeholder:text-[#7787A6] sm:text-base text-sm"
                    {...register("password")}
                  />
                </div>
                <p className="text-red-500 text-sm">
                  {errors.password?.message}
                </p>
              </div>
              <button
                className="cstm-btn rounded-[5px] px-6 py-2 sm:text-base text-sm"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Logging in..." : "Login"}
              </button>
              <h2
                className="sm:text-sm text-xs -mt-2 cursor-pointer"
                onClick={onOpenResetPassword}
              >
                Forgot Password?
              </h2>
            </div>
          </form>
          <div className="h-[2px] bg-[#898989] flex items-center justify-center">
            <p className="bg-[#040A20] w-fit px-2 text-sm">OR</p>
          </div>
          <div className="sm:px-8 px-6 sm:pt-12 pt-6 sm:pb-16 pb-6">
            <button
              className="w-full bg-white text-black rounded-[5px] px-6 py-2 sm:text-base text-sm"
              onClick={onOpenSignup}
            >
              Create an Account
            </button>
          </div>
        </div>
        {/* <SignupPopUp /> */}
        {/* {SignupPopup && <SignupPopUp HandleSignupClose={HandleSignupClose} />} */}
      </div>
    </div>
  );
}

LoginPopup.propTypes = {
  onCloseSignin: PropTypes.func,
  onOpenSignup: PropTypes.func,
  onOpenResetPassword: PropTypes.func,
};
