import React from "react";
import { useRoutes } from "react-router-dom";
import Hero from "./Hero";
import { PrivateRoute } from "./PrivateRoute";
import ResetPassword from "./ResetPassword";
import { SignupWithReferral } from "./SignupWithReferral";

const Dashboard = React.lazy(async () => await import("./Dashboard"));
const Assessment = React.lazy(async () => await import("./Assessment"));

export const Routes = () => {
  const element = useRoutes([
    { path: "/", element: <Hero /> },
    {
      path: "signup",
      element: <SignupWithReferral />,
    },
    { path: "/reset-password", element: <ResetPassword /> },
    {
      path: "/",
      element: <PrivateRoute />,
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "assessment",
          element: <Assessment />,
        },
      ],
    },
    { path: "/*", element: <div>Not Found</div> },
  ]);

  return element;
};
