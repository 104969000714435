import storage from "../utils/storage";
import api from "./api";

export const login = (credientials, config) => {
  return api.post("/auth", credientials, { ...config, credentials: "include" });
};

export const fetchUser = (config) => {
  return api.get("/users/me", config);
};

export const register = (data, config) => {
  return api.post("/users", data, config);
};

export const logout = () => {
  storage.clearTokens();
};
