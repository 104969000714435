import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "@/api/api";
import { fetchSectionQuestions } from "./sections";
import { handleAsyncThunkError } from "@/utils/handleAsyncThunkError";

export const createAnswer = createAsyncThunk(
  "chat/createAnswer",
  async (data, thunkAPI) => {
    try {
      const response = await api.post("/answers", data);
      return response.data;
    } catch (error) {
      return handleAsyncThunkError(error, thunkAPI);
    }
  },
);

export const updateAnswer = createAsyncThunk(
  "chat/updateAnswer",
  async ({ answerId, ...data }, thunkAPI) => {
    try {
      const response = await api.patch(`/answers/${answerId}`, data);
      return { ...response.data, answerText: data.text };
    } catch (error) {
      return handleAsyncThunkError(error, thunkAPI);
    }
  },
);

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    messages: [],
    answer: {},
    status: "idle",
    error: null,
  },
  reducers: {
    addQuestion: (state, action) => {
      state.messages.push({ type: "question", ...action.payload });
    },
    addAnswer: (state, action) => {
      state.messages.push({ type: "answer", ...action.payload });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAnswer.pending, (state) => {
        state.status = "pending";
      })
      .addCase(createAnswer.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.answer = action.payload;
      })
      .addCase(createAnswer.rejected, (state) => {
        state.status = "failed";
      });
    builder
      .addCase(updateAnswer.pending, (state) => {
        state.status = "pending";
      })
      .addCase(updateAnswer.fulfilled, (state, action) => {
        state.status = "fulfilled";
        const answerText = action.payload.answerText;
        if (answerText) {
          state.messages.push({
            type: "answer",
            ...action.payload,
            text: answerText,
          });
        }
      })
      .addCase(updateAnswer.rejected, (state) => {
        state.status = "failed";
      });
    builder.addCase(fetchSectionQuestions.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.messages = [{ type: "question", ...action.payload[0] }];
      }
    });
  },
});

export const { addQuestion, addAnswer } = chatSlice.actions;
export default chatSlice.reducer;
