import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "@/api/api";
import { handleAsyncThunkError } from "@/utils/handleAsyncThunkError";

export const createAssessment = createAsyncThunk(
  "assessment/createAssessment",
  async (_, thunkAPI) => {
    try {
      const response = await api.post("/assessments");
      return response.data;
    } catch (error) {
      return handleAsyncThunkError(error, thunkAPI);
    }
  },
);

export const updateAssessment = createAsyncThunk(
  "assessment/updateAssessment",
  async ({ assessmentId, ...data }, thunkAPI) => {
    try {
      const response = await api.patch(`/assessments/${assessmentId}`, data);
      return response.data;
    } catch (error) {
      return handleAsyncThunkError(error, thunkAPI);
    }
  },
);

export const uploadRecordedVideo = createAsyncThunk(
  "assessment/uploadRecordedVideo",
  async ({ assessmentId, data }, thunkAPI) => {
    try {
      const response = await api.post(
        `/assessments/${assessmentId}/upload-video`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      return response.data;
    } catch (error) {
      return handleAsyncThunkError(error, thunkAPI);
    }
  },
);

export const fetchNextAssessmentDate = createAsyncThunk(
  "assessment/fetchNextAssessmentDate",
  async (_, thunkAPI) => {
    try {
      const response = await api.get("/assessments/next-date");
      return response.data;
    } catch (error) {
      return handleAsyncThunkError(error, thunkAPI);
    }
  },
);

export const fetchCompletedAssessmentsCount = createAsyncThunk(
  "assessment/fetchCompletedAssessmentsCount",
  async (_, thunkAPI) => {
    try {
      const response = await api.get("/assessments/completed-count");
      return response.data;
    } catch (error) {
      return handleAsyncThunkError(error, thunkAPI);
    }
  },
);

export const fetchLastAssessmentCompletionDate = createAsyncThunk(
  "assessment/fetchLastAssessmentCompletionDate",
  async (_, thunkAPI) => {
    try {
      const response = await api.get("/assessments/last-completion-date");
      return response.data;
    } catch (error) {
      return handleAsyncThunkError(error, thunkAPI);
    }
  },
);

const assessmentSlice = createSlice({
  name: "assessment",
  initialState: {
    assessment: null,
    completedAssessmentsCount: 0,
    lastAssessmentCompletionDate: null,
    status: "idle",
    videoUploadStatus: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAssessment.fulfilled, (state, action) => {
      state.assessment = action.payload;
    });
    builder.addCase(updateAssessment.fulfilled, (state, action) => {
      state.assessment = action.payload;
    });
    builder
      .addCase(uploadRecordedVideo.pending, (state) => {
        state.videoUploadStatus = "pending";
      })
      .addCase(uploadRecordedVideo.fulfilled, (state) => {
        state.videoUploadStatus = "success";
      })
      .addCase(uploadRecordedVideo.rejected, (state) => {
        state.videoUploadStatus = "error";
      });
    builder.addCase(
      fetchCompletedAssessmentsCount.fulfilled,
      (state, action) => {
        state.completedAssessmentsCount =
          action.payload.completedAssessmentsCount;
      },
    );
    builder.addCase(
      fetchLastAssessmentCompletionDate.fulfilled,
      (state, action) => {
        state.lastAssessmentCompletionDate =
          action.payload.lastAssessmentCompletionDate;
      },
    );
  },
});

export default assessmentSlice.reducer;
