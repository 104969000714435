import { Outlet, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useAuth } from "../context/AuthContext";
import "react-toastify/dist/ReactToastify.css";

export const PrivateRoute = () => {
  const { isLoggedIn } = useAuth();

  return isLoggedIn ? (
    <>
      <Outlet />
      <ToastContainer />
    </>
  ) : (
    <Navigate to="/" />
  );
};
