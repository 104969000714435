import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword, validateResetPasswordToken } from "@/redux/slices/auth";
import { APP_NAME } from "@/utils/constants";

const signupSchema = z
  .object({
    password: z
      .string()
      .min(8, "Password must be at least 8 characters long")
      .max(100, "Password cannot exceed 100 characters")
      .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
      .regex(/[a-z]/, "Password must contain at least one lowercase letter")
      .regex(/[0-9]/, "Password must contain at least one number"),
    confirmPassword: z.string().min(1, "Confirm Password is required"),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

export default function ResetPassword() {
  const dispatch = useDispatch();
  const { status, error: resetPasswordError } = useSelector(
    (state) => state.auth,
  );
  const [validateTokenPending, setValidateTokenPending] = useState("loading");
  const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: zodResolver(signupSchema),
  });

  useEffect(() => {
    if (token) {
      dispatch(validateResetPasswordToken(token))
        .unwrap()
        .catch(() => navigate("/"))
        .finally(() => setValidateTokenPending("idle"));
    }
  }, [token]);

  const onSubmit = async (credentials) => {
    dispatch(resetPassword({ token, credentials }))
      .unwrap()
      .then(() => setPasswordResetSuccessful(true));
  };

  const isLoading = status === "loading";

  if (validateTokenPending === "loading") return <div>Loading...</div>;

  return (
    <div>
      <header>
        <div className="w-full md:px-16 px-6 py-4 flex justify-between">
          <div className="flex items-center gap-1">
            <img src="logo.svg" alt="" className="" />
            <h1 className="text-4xl font-semibold">{APP_NAME}</h1>
          </div>
        </div>
      </header>
      <div className="bg-[#040A20] flex justify-center">
        {passwordResetSuccessful ? (
          <div className="flex flex-col items-center">
            <div className="text-white text-lg font-semibold pt-16 pb-8">
              Password Reset Successful.
            </div>
            <Link
              to="/"
              className="cstm-btn rounded-[5px] px-6 py-2 sm:text-base text-sm"
            >
              Go Home
            </Link>
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className="w-[420px]">
            <div className="sm:py-12 py-6 sm:px-8 px-6 flex flex-col gap-5">
              <p className="text-red-500">{resetPasswordError}</p>
              <div className="flex flex-col gap-1">
                <div className="bg-[#1E2748] sm:py-3 py-2 sm:px-4 px-2 flex items-center gap-2 rounded-[5px]">
                  <input
                    type="password"
                    placeholder="Password"
                    className="w-full bg-transparent focus-visible:outline-none placeholder:text-[#7787A6] sm:text-base text-sm"
                    {...register("password")}
                  />
                </div>
                <p className="text-red-500 text-sm">
                  {errors.password?.message}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <div className="bg-[#1E2748] sm:py-3 py-2 sm:px-4 px-2 flex items-center gap-2 rounded-[5px]">
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    className="w-full bg-transparent focus-visible:outline-none placeholder:text-[#7787A6] sm:text-base text-sm"
                    {...register("confirmPassword")}
                  />
                </div>
                <p className="text-red-500 text-sm">
                  {errors.confirmPassword?.message}
                </p>
              </div>
              <button
                className="cstm-btn rounded-[5px] px-6 py-3 md:mt-4 sm:text-base text-sm"
                disabled={isLoading}
              >
                {isLoading ? "Resetting Password..." : "Reset Password"}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
