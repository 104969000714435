import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "@/api/api";
import { handleAsyncThunkError } from "@/utils/handleAsyncThunkError";

export const requestPasswordReset = createAsyncThunk(
  "auth/requestPasswordReset",
  async (email, thunkAPI) => {
    try {
      const response = await api.post("/auth/forgot-password", { email });
      return response.data;
    } catch (error) {
      return handleAsyncThunkError(error, thunkAPI);
    }
  },
);

export const validateResetPasswordToken = createAsyncThunk(
  "auth/resetPassword",
  async (token, thunkAPI) => {
    try {
      const response = await api.get(`/auth/reset-password/${token}`);
      return response.data;
    } catch (error) {
      return handleAsyncThunkError(error, thunkAPI);
    }
  },
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ token, credentials }, thunkAPI) => {
    try {
      const response = await api.post(
        `/auth/reset-password/${token}`,
        credentials,
      );
      return response.data;
    } catch (error) {
      return handleAsyncThunkError(error, thunkAPI);
    }
  },
);

const assessmentSlice = createSlice({
  name: "auth",
  initialState: {
    status: "idle",
    error: null,
  },
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestPasswordReset.pending, (state) => {
        state.status = "loading";
      })
      .addCase(requestPasswordReset.fulfilled, (state) => {
        state.status = "success";
      })
      .addCase(requestPasswordReset.rejected, (state, action) => {
        state.status = "error";
        state.error =
          action.payload?.message ||
          "Request password reset failed. Please try again.";
      });
    builder
      .addCase(resetPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.status = "success";
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = "error";
        state.error =
          action.payload?.message ||
          "Request password reset failed. Please try again.";
      });
  },
});

export const { resetStatus } = assessmentSlice.actions;

export default assessmentSlice.reducer;
