import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Suspense } from "react";
import { Routes } from "./components/Routes";
import { APP_NAME } from "./utils/constants";

function App() {
  return (
    <Router>
      <div className="App text-white">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{APP_NAME}</title>
        </Helmet>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes />
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
