import api from "@/api/api";
import { handleAsyncThunkError } from "@/utils/handleAsyncThunkError";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async ({ page = 1, limit = 10, sort = "desc" } = {}, thunkAPI) => {
    try {
      const response = await api.get(`/notifications`, {
        params: { page, limit, sort },
      });
      return response.data;
    } catch (error) {
      handleAsyncThunkError(error, thunkAPI);
    }
  },
);

export const markAsRead = createAsyncThunk(
  "notifications/markAsRead",
  async (notificationId, thunkAPI) => {
    try {
      const response = await api.patch(`/notifications/${notificationId}`);
      return response.data;
    } catch (error) {
      handleAsyncThunkError(error, thunkAPI);
    }
  },
);

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    notifications: [],
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    limit: 10,
    status: "idle",
    error: null,
  },
  reducers: {
    addNotification: (state, action) => {
      state.notifications.unshift(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = "fulfilled";
        Object.assign(state, action.payload);
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch notifications";
      })
      .addCase(markAsRead.fulfilled, (state, action) => {
        const index = state.notifications.findIndex(
          (n) => n._id === action.payload._id,
        );
        if (index !== -1) {
          state.notifications[index].isRead = true;
        }
      });
  },
});

export const { addNotification } = notificationsSlice.actions;
export default notificationsSlice.reducer;
