import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { requestPasswordReset, resetStatus } from "@/redux/slices/auth";

const loginSchema = z.object({
  email: z.string().min(1, "Email is required"),
});

export default function ForgotPasswordPopup({
  onOpenSignin,
  onCloseResetPassword,
}) {
  const dispatch = useDispatch();
  const { status, error: forgotPasswordError } = useSelector(
    (state) => state.auth,
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: zodResolver(loginSchema),
  });

  const onSubmit = async ({ email }) => {
    dispatch(requestPasswordReset(email));
  };

  const isLoading = status === "loading";

  return (
    <div className="h-screen fixed inset-0 backdrop-blur-lg	flex justify-center items-center">
      <div className="sm:w-[480px]">
        <div className="flex justify-between bg-[#2776F9] sm:py-4 py-2 sm:px-8 px-6">
          <h1 className="sm:text-2xl text-xl font-semibold">
            Request Password Reset
          </h1>
          <img
            src="/close.svg"
            alt=""
            width="15"
            className="cursor-pointer"
            onClick={() => {
              dispatch(resetStatus());
              onCloseResetPassword();
            }}
          />
        </div>
        <div className="bg-[#040A20]">
          {status === "success" ? (
            <div className="sm:pt-12 pt-6 sm:pb-8 pb-6 sm:px-8 px-6 flex flex-col gap-5">
              <p className="text-gray-300 text-lg">
                We have sent you an email with a link to reset your password.
                Please check your inbox for the email.
              </p>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="sm:pt-12 pt-6 sm:pb-8 pb-6 sm:px-8 px-6 flex flex-col gap-5">
                <p className="text-red-500">{forgotPasswordError}</p>
                <div className="flex flex-col gap-1">
                  <div className="bg-[#1E2748] sm:py-3 py-2 sm:px-4 px-2 flex items-center gap-2 rounded-[5px]">
                    <img
                      src="/user.svg"
                      alt=""
                      width="20"
                      className="sm:w-[20px] w-[15px]"
                    />
                    <input
                      type="text"
                      placeholder="Email"
                      className="w-full bg-transparent focus-visible:outline-none placeholder:text-[#7787A6] sm:text-base text-sm"
                      {...register("email")}
                    />
                  </div>
                  <p className="text-red-500 text-sm">
                    {errors.email?.message}
                  </p>
                </div>
                <button
                  className="cstm-btn rounded-[5px] px-6 py-2 sm:text-base text-sm"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Requesting Reset..." : "Request Reset"}
                </button>
                <h2
                  className="sm:text-sm text-xs -mt-2 cursor-pointer"
                  onClick={onOpenSignin}
                >
                  Return to Login
                </h2>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

ForgotPasswordPopup.propTypes = {
  onOpenSignin: PropTypes.func,
  onCloseResetPassword: PropTypes.func,
};
